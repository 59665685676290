import React, { useEffect } from 'react'
import Job from '../Components/Jobs/index'
import Banner from '../Components/Banner/index'
import HeroSection from '../Components/HeroSection/index'
import Footer from '../Components/Footer/index'

const JobsPage = () => {
  useEffect(()=>{

  },[])
  return (
    <>
    <HeroSection />
    <Job />
    <Banner />
    <Footer />
    </>
  )
}

export default JobsPage