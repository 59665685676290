// src/JobApplicationForm.js
import React, { useState } from 'react';
import './style.css'

const JobApplicationForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic (e.g., send to API)
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('resume', resume);

    // Here you could send formData to your server using fetch or axios
    console.log('Form submitted', { name, email, resume });
    setMessage('Application submitted successfully!');
  };

  return (
    <div className='jobForm'>
      <h1>Job Application Form</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Resume:</label>
          <input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={(e) => setResume(e.target.files[0])}
            required
          />
        </div>
        <button className='btn' type="submit">Apply</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default JobApplicationForm;
