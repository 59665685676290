import React from 'react'
import NexGenLogo from '../../Assets/NexGen_Logo.png'
import './style.css'
import { Link } from 'react-router-dom'

const Index = () => {
  return (
    <>
    <div className="navbarContainer">
        <div className="nexgenLogo">
            <Link to="/"><img src={NexGenLogo} alt="" /></Link>
        </div>
        <div className="menuItems">
           <Link to="https://www.nexgentechsolutions.com">Navigate To Main Website</Link>
        </div>
    </div>
    </>
  )
}

export default Index