import React from 'react'
import Hero from '../../Assets/Hero.webp'
import './style.css'

const Index = () => {
  return (
    <>
    <div className="heroContainer">
        {/* <img src={Hero} alt="" /> */}
        <h1>Career</h1>
    </div>
    </>
  )
}

export default Index