import React from 'react'
import data from '../../Assets/Data/data.json'
import { Link } from 'react-router-dom'
import './style.css'
import NGLogo from '../../Assets/NexGen_Logo.png'

const Index = () => {
  return (
    <>
    <h1>Featured Jobs</h1>
    <p style={{maxWidth: "50%", margin: "10px auto", marginBottom: "40px"}}>We’re looking for dedicated, technologists, and creative people to join us. Explore our current open positions. If you find an opportunity that aligns with your skills, we would love to hear from you.</p>
    {data.map((item)=>{
        return <Link to={`/Jobs/${item.id}`}>
        <div key={item.id} className="jobContainer">
            <img src={NGLogo}  alt="" />
            <div className="" style={{display:"flex", flexDirection:"column", margin:"0"}}>
            <h3>{item.Job}</h3>
            <div className="jobDescription">
            <p><b>Key Skills: </b>{item['Key Skills']}</p>
            <p style={{marginLeft:"10px"}}><b>Location: </b>{item['Location']}</p>
            </div>
            </div>
            <div className="dateContainer">
                <h6>{item.JobPosted}</h6>
            </div>
        </div>
        </Link>
    })}
    </>
  )
}

export default Index