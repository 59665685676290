import './App.css';
import Navbar from './Components/Navbar/index'
import JobsPage from './Pages/JobsPage'
import JobDescription from './Pages/JobDescription';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar /> 
     <Routes>
      <Route path='/' element={<JobsPage/>} />
      <Route path='/Jobs/:JobId' element={<JobDescription/>} />
     </Routes>
      </BrowserRouter>
     
    </div>
  );
}

export default App;
