import React from 'react'
import Career from '../../Assets/Career.jpg'
import './style.css'

const Index = () => {
  return (
    <>
    <div className="bannerContainer">
        <div className="bannerText">
            <h1>A Place to Grow</h1>
            <p>At NexGen, we are constantly working to build solutions that are meaningful and lasting for our clients to realize their business goals. We measure success by the value we create for them. Through the past ten years of working with the best minds in the industry, we have been successful in identifying and nurturing a highly-skilled, agile, collaborative, and motivated talent pool. Working alongside them can be a fantastic opportunity for you to build your career and constantly progress.</p>
        </div>
        <div className="careerImg">
        <img src={Career} alt="" />
        </div>
    </div>
    </>
  )
}

export default Index