import React from 'react'
import './style.css'

const Index = () => {
  return (
    <>
    <div className="foooterContainer">
        CopyRight 2024 @ Nexgen Tech Solutions
    </div>
    </>
  )
}

export default Index