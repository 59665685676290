import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import data from '../Assets/Data/data.json'
import './style.css'
import Form from '../Components/Form/Form'

const JobDescription = () => {
  const { JobId } = useParams()
  const [form, setForm] = useState(false)
  console.log(JobId, "JOBID")
  const JobDetails = data.find((e) => e.id === Number(JobId))
  return (
    <>
      <div className="jobDetailsContainer">
        <h1>{JobDetails.Job}</h1>
        <table cellSpacing="2px" cellPadding="10px" style={{width:"70%"}}>
          <tbody>
            <tr>
              <th>Location:</th>
              <td><p>{JobDetails['Location']}</p></td>
            </tr>
            <tr>
              <th>Key Skills:</th>
              <td><p>{JobDetails['Key Skills']}</p></td>
            </tr>
            <tr>
              <th>Posted On:</th>
              <td><p>{JobDetails.JobPosted}</p></td>
            </tr>
            <tr>
              <th>Experience:</th>
              <td><p>{JobDetails.Experience}</p></td>
            </tr>
          </tbody>
        </table>
        <div className="jobDescriptions">
          <h3>Description</h3>
          <div dangerouslySetInnerHTML={{ __html: JobDetails['Job Description'] }}></div>
          <h3>Key Qualifications</h3>
          {JobDetails['Key Qualifications'].map((item, index)=>{
            return <ul>
              <li key={index}>{item}</li>
            </ul>
          })}
        </div>
        {form?"":<button onClick={()=>setForm(!form)}>Apply Now</button>}
        <span style={{marginTop:"5%"}}><b>OR Send Your Profile to: hiring@nexgeniots.com</b></span>
      </div>
        {form && <Form />}
    </>
  )
}

export default JobDescription